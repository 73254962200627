import React, { PureComponent } from 'react';
import {
  Select,
  Form,
  message,
  Input,
} from 'antd';
import { CustomerSelectModal, SelectButton, AddressCreateDialog, Layout, Button} from '@/components';
import SaleOrderDetailEditor from '../components/sale_order_detail_editor/SaleOrderDetailEditor';
import apis from '@/api/api';
import { FormInstance } from 'antd/lib/form/util';
import SaleConfirmDialog from './SaleOrderConfirmDialog';
import WebApi from '@/web/api';
import BigNumber from 'bignumber.js';
import { LibIAgentSaleOrderCreateDetail } from 'lib/interface/SaleOrderInterface';

export default class SaleCreate extends PureComponent<RouterPropsType> {

  private form = React.createRef<FormInstance>()
  private addressCreate = React.createRef<AddressCreateDialog>()

  private customerSM: CustomerSelectModal | null = null;

  private editProduct = React.createRef<SaleOrderDetailEditor>();

  private postSaleParams = {
    customerId: '',
    products: [],
    consigneeAddress: '',
    consigneeName:'',
    consigneeMobile: '',
    remark: '',
  }

  state: {
    customerId: string,
    customerName: string,
    confirmVisible: boolean,
    findAddressLoading: boolean,
    addressList: any[],
    products: any[],
    customerOptions: any[],
    customersLoading: boolean,
  }

  constructor(props) {
    super(props);
    this.state = {
      customerId: '',
      findAddressLoading: false,
      customerName: '',
      confirmVisible: false,
      addressList: [],
      products: [],
      customerOptions: [],
      customersLoading: true,
    };
  }

  componentDidMount() {
    this.findCustomers();
  }

  // 查询最近代下单的20个客户
  findCustomers = () => {
    WebApi.sale_customer_List()
    .then(data => {
      this.setState({
        customerOptions: data,
        customersLoading: false
      });
    })
  }

  private getAddressList = (customerId) => {
    this.setState({
      findAddressLoading: true,
    });
    WebApi.customer_address_list({
      customerId,
    })
    .then((addressList: any) => {
      this.setState({
        addressList,
        findAddressLoading: false,
      }, () => {
        this.form.current?.setFieldsValue({
          addressId: addressList[0]?.addressId,
        });
      });
    })
    .catch(e => message.error(e.message));
  };

  render() {
    const {
      customerName,
      confirmVisible,
      findAddressLoading,
      customerOptions,
      customersLoading,
    } = this.state;

    return <Layout.Page>
        <Layout flex1 overHidden flexColumn>
          <Layout.Title>订货信息</Layout.Title>
          <Form ref={this.form}>
            <Form.Item label="选择客户" name="customerName" rules={[{
              required: true,
              message: '请选择客户',
            }]}>
              <Select
                showSearch
                allowClear
                loading={customersLoading}
                style={{width: 400}}
                placeholder="选择客户"
                onChange={(v, option) => {
                  if (v) {
                    this.setState({
                      customerName: option['label'],
                      customerId: option['value'],
                    });
                    this.getAddressList(v);
                  } else {
                    this.setState({
                      customerName: '',
                      customerId: '',
                    });
                    this.form.current?.setFieldsValue({
                      addressId: '',
                    });
                  }
                  this.editProduct.current?.reset([]);
                }}
                options={customerOptions.map(item => {
                  return {
                    label: item.customerName,
                    value: item.customerId,
                  };
                })}
                dropdownRender={(menu) => {
                  return <div>
                    {menu}
                    <SelectButton type="find" onClick={() => {
                      this.customerSM?.open()
                    }}></SelectButton>
                  </div>
                }}
              />
            </Form.Item>
            <Form.Item label="收货地址" name="addressId" rules={[{
              required: true,
              message: '请选择收货地址',
            }]}>
              <Select
                disabled={!customerName}
                style={{width: 400}}
                loading={findAddressLoading}
                placeholder={customerName ? '选择收货地址' : '请先选择客户'}
                dropdownRender={menu => {
                  return <div>
                    {menu}
                    <SelectButton type="create" onClick={() => {
                      this.addressCreate.current?.open(this.state.customerName, this.state.customerId);
                    }}>新增收获地址</SelectButton>
                  </div>
                }}
              >
                {
                  this.state.addressList.map(item => {
                    return <Select.Option value={item.addressId} key={item.addressId}>
                      {item.name} {item.mobile} {item.cityName} {item.areaName} {item.address}
                    </Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label={<div style={{width: 58, textAlign: 'right'}}>备注说明</div>} name="remark">
              <Input style={{width: 400}} className="underline" onChange={e => {
                this.setState({
                  remark: e.target.value
                });
              }}/>
            </Form.Item>
          </Form>
          
          <Layout.Title icon="icon-form-shangpin">商品明细</Layout.Title>
          <Layout flex1 overHidden flexColumn>
            <SaleOrderDetailEditor
              ref={this.editProduct}
              onAdd={() => {
                this.form.current?.validateFields();
              }}
              dataSource={[]}
              customerId={this.state.customerId}
            />
          </Layout>
        
        </Layout>
        <Layout.FootBar>
          <Button type="primary"  onClick={this.clickSaveOrder}>保存</Button>
        </Layout.FootBar>
        <CustomerSelectModal
          ref={r => this.customerSM = r}
          onOk={(data: any) => {
            const {customerId, customerName} = data;
            this.setState({
              customerName,
              customerId,
              findAddressLoading: true,
            });
            this.getAddressList(customerId);
            this.form.current?.setFieldsValue({
              customerName,
            });
            this.customerSM?.close();
            // 清空已选的商品
            this.editProduct.current?.clearProducts();
          }}
        />

        <AddressCreateDialog ref={this.addressCreate} onOk={() => {
          this.getAddressList(this.state.customerId);
        }}/>

        <SaleConfirmDialog visible={confirmVisible}
          onOk={this.clickOkCreateOrder}
          onCancel={() => this.setState({confirmVisible: false})}
          customerName={this.state.customerName}
          remark={this.postSaleParams.remark}
          address={this.getFullConsigneeData()}
          products={this.state.products}
        />
    </Layout.Page>
  }

  getFullConsigneeData = () => {
    const a = this.postSaleParams;
    return `${a.consigneeName} ${a.consigneeMobile} ${a.consigneeAddress}`
  }

  // 保存订单
  clickSaveOrder = async () => {
    this.form.current?.validateFields()
    .then((values) => {
      this.postSaleParams.customerId = this.state.customerId;
      const address = this.state.addressList.find(a => a.addressId === values.addressId);
      if (!address) {
        message.error('请选择收货地址');
        return;
      }
      this.postSaleParams.consigneeAddress = address.provinceName + address.cityName + address.areaName + address.street;
      this.postSaleParams.consigneeMobile = address.mobile;
      this.postSaleParams.consigneeName = address.name;
      this.postSaleParams.remark = values.remark;

      
      this.editProduct.current?.validateProducts().then(products => {
        this.postSaleParams.products = products.map((item: any) => {
          return {
            productId: item.productId,
            productName: item.productName,
            skuId: item.skuId,
            skuName: item.skuName,
            orderPrice: item.isGift ? 0 :item.orderPrice,
            orderUnitId: item.orderUnitId,
            orderUnitName: item.orderUnitName,
            orderCount: item.orderCount,
            isGift: item.isGift,
            remark: item.remark,
            payAmount: item.isGift ? 0 : new BigNumber(item.orderPrice).multipliedBy(item.orderCount).toFixed(2),
          };
        }) as any;
  
        this.setState({
          confirmVisible: true,
          products: this.postSaleParams.products
        });
      })
    })
    .catch(e => console.error(e))
  }

  clickOkCreateOrder = () => {
    WebApi.sale_order_create({
      customerId: this.postSaleParams.customerId,
      consigneeName: this.postSaleParams.consigneeName,
      consigneeMobile: this.postSaleParams.consigneeMobile,
      consigneeAddress: this.postSaleParams.consigneeAddress,
      detailList: this.postSaleParams.products.map((item: LibIAgentSaleOrderCreateDetail) => {
        return {
          productName: item.productName,
          productId: item.productId,
          skuName: item.skuName,
          skuId: item.skuId,
          orderCount: item.orderCount,
          orderPrice: item.orderPrice,
          orderUnitName: item.orderUnitName,
          orderUnitId: item.orderUnitId,
          isGift: item.isGift,
          remark: item.remark,
        };
      }),
      remark: this.postSaleParams.remark,
    })
    .then(() => {
      message.success('下单成功');
      this.setState({
        confirmVisible: false,
      });
      this.postSaleParams.products = [];
      // 清空
      this.editProduct.current?.clearProducts();
      this.props.history.close();
    })
    .catch(e => message.error(e.message));
  }
}